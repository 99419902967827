// extracted by mini-css-extract-plugin
export var bases = "PriceCalculator-module--bases--2Y49K";
export var base = "PriceCalculator-module--base--3OO22";
export var base_header = "PriceCalculator-module--base_header--29K4P";
export var base_perks = "PriceCalculator-module--base_perks--3wOxp";
export var base_price = "PriceCalculator-module--base_price--3gJaC";
export var base_landing = "PriceCalculator-module--base_landing--2B1wk";
export var base_company = "PriceCalculator-module--base_company--3hPzk";
export var base_commerce = "PriceCalculator-module--base_commerce--3ipeP";
export var optionsTitle = "PriceCalculator-module--optionsTitle--3ASh0";
export var optionsBlocks = "PriceCalculator-module--optionsBlocks--2e1hr";
export var optionBlock = "PriceCalculator-module--optionBlock--1CdYX";
export var options = "PriceCalculator-module--options--2mS0K";
export var option = "PriceCalculator-module--option--3XdDc";
export var option_header = "PriceCalculator-module--option_header--2ea-C";
export var option_title = "PriceCalculator-module--option_title--3zxtk";
export var option_price = "PriceCalculator-module--option_price--3ikoz";
export var option_content = "PriceCalculator-module--option_content--3Iof2";
export var packs = "PriceCalculator-module--packs--2wZM4";
export var pack = "PriceCalculator-module--pack--22gJV";
export var pack_header = "PriceCalculator-module--pack_header--CLsuL";
export var pack_title = "PriceCalculator-module--pack_title--2kKfN";
export var pack_price = "PriceCalculator-module--pack_price--15dct";