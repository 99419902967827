import React, { useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper, faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faUsers, faBullhorn, faGlobe, faBriefcase, faShop, faEllipsis } from '@fortawesome/free-solid-svg-icons'

import * as s from './PriceCalculator.module.scss';


const OPTIONS = [
    {
        title: `Page de contenu supplémentaire`,
        price: 45,
    },
    {
        title: `Création d'un média de présentation`,
        price: {
            min: 30,
            max: 100,
        },
        content: `Création d'une image de communication web, d'un logo, d'un flyer`,
    },
    {
        title: `Module de réservation en ligne - rendez-vous, cours, places disponibles, chambres...`,
        price: {
            min: 80,
            max: 150,
        },
        content: `Vos visiteurs verront un calendrier pour réserver une place dans votre agenda. Vous recevrez les réservations par e-mail et elles seront consultables dans votre administration.
<br/>Ou vos visiteurs verront un module de réservation avancé. Vos chambres / activités auront une page de présentation individuelle.
<br/>
<br/>Types de réservations : hotel / auberge / gite, coiffeur / tattoo, activités de groupe, ...`,
},
{
    title: `Liste et administration d'objet`,
    price: {
        min: 50,
        max: 150,
    },
    content: `Administrez et affichez des listes de contenu. Exemples : biens immobiliers, oeuvres artistiques, collaborateurs, ...`
},
{
    title: `Newsletter`,
    price: 20,
},
{
    title: `Formulaire de contact`,
    price: 20,
},
{
    title: `Statistiques de visites`,
    price: 20,
},
{
    title: `Galerie photo`,
    price: 20,
},
{
    title: `Rédaction du contenu par page : textes et sélection d'images`,
    price: {
        min: 15,
        max: 50,
    },
    content: `Si vous n'êtes pas loin, nous nous déplaçons et nous réalisons des photos pour vous -- sur Devis.
<br/>Ceci est un service proposé pour des pages d'un site web que vous possedez déjà, si vous achetez des pages de contenu pour la création d'un nouveau site web, le prix sera réduit.`
},
{
        title: `Contenu payant et gratuit`,
        price: 70,
        content: `Publiez des articles réservés aux abonnés. Ou vendez un document numérique`,
    },
    {
        title: `Site web multi-langues`,
        price: 'Sur Devis',
    }
    // {
    //     title: `Chat en ligne`,
    //     price: 40,
    // },
]


const PACKS = [
    {
        title: `Marketing & SEO`,
        price: `Sur Devis`,
        desc: (<>
            Visibilité web-marketing : SEO moteurs de recherche, site webs specifique de referencement, reseaux sociaux
            <br/>Recommandation et installation d'outils digitaux & plus pour votre activité
            <br/>Communication par e-mail et en papier
            <br/>Réalisations de logos
        </>)
    },
    // {
    //     title: `Support et modifications simples pendant 1 an`,
    //     price: 200,
    //     desc: (<>
    //         Assistance pour répondre à vos questions et vous conseiller
    //         <br/><br/>
    //         Résolutions de bugs - liés à votre thème, ou votre hébergeur web
    //         <br/><br/>
    //         Modifications simples : nous vous accompagnons pour personnaliser votre site web pour des modifications simples. Nous nous réservons le droit de refuser celle-ci si elle représente une modification plus complexe.
    //         Exemples de modifications simples : retrait de bloc, changement des espacements, modification du style d'un bloc, ajout d'un slider, ajout de langues, configuration d'un module, ...
    //         Exemples de modifications complexes : re-design d'une page complète, changement de thème avec personalisation, ...`,
    //     </>),
    // },
    {
        title: `Formation & accompagnement`,
        price: '30 € / heure -- Sur Devis',
        desc: `Nous vous présentons l'interface de WordPress. Nous vous expliquons le fonctionnement des articles, de la bibliothèque de médias, des catégories, des nombreux plugins. Il y a beaucoup de plugins WordPress disponibles et nous vous conseillons selon votre activité.`
    },
    {
        title: `Personnalisation du design graphique avancée`,
        price: 'Sur Devis',
        desc: `Notre offre de base comprend des personalisations et des retouches simples.`
    },
    {
        title: `Si votre fonctionnalité n'apparait pas dans cette liste`,
        price: 'Sur Devis',
        desc: `Nous démarrons notre activité et nous cherchons quels sont les modules, les designs, les outils que nos clients veulent. Nous avons les compétences de développement web pour répondre à votre demande. Contactez-nous et nous vous proposerons une solution!`
    }
]


export default function PriceCalculator() {
    const [baseSelected, setBaseSelected] = useState(null);

    return (
        <div id={s.calculator}>
            <div id={s.optionsBlocks}>
                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faBriefcase} size="3x" />
                    <h4>Votre Entreprise</h4>
                </div>

                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faShop} size="3x" />
                    <h4>Boutique en ligne</h4>
                </div>
    
                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faNewspaper} size="3x" />
                    <h4>Journal, Blog,<br/>Magazine</h4>
                </div>

                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faUsers} size="3x" />
                    <h4>Abonnements<br/>& Contenu exclusif</h4>
                </div>

                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faCalendar} size="3x" />
                    <h4>Réservations</h4>
                </div>

                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faBullhorn} size="3x" />
                    <h4>Pages publicitaires<br/>& Marketing</h4>
                </div>

                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faGlobe} size="3x" />
                    <h4>Multi-langues</h4>
                </div>

                <div className={s.optionBlock}>
                    <FontAwesomeIcon icon={faEllipsis} size="3x" />
                    <h4>... et plus encore</h4>
                </div>
            </div>

            <div id={s.bases}>
                <div id={s.base_landing} className={cx(s.base, baseSelected === 'vitrine' && s.selected)}>
                    <div className={s.base_header}>
                        <h3>Site Web 1 à 2 pages</h3>
                        
                        <span className={s.base_price}>
                            150 €
                        </span>
                    </div>
                    <ul className={s.base_perks}>
                        <li>Installation et configuration de votre site web (https://votreadresse.com/)</li>
                        <li>Choix d'un thème graphique avec un design adapté aux mobiles, tablettes et aux ordinateurs. Nous avons des centaines de thèmes, nous sélectionnerons selon vos demandes</li>
                        <li>Customisation personalisée du thème, modification de la police, ajout de marges, choix des couleurs, retrait de blocs et ajout de blocs, pied de page et haut de page...</li>
                        <li className={s.strong}>1 à 2 pages - vous fournissez le texte et les images</li>
                        <li>Nous vous présenterons votre interface d'administration et vous accompagnerons pour son utilisation</li>
                    </ul>
                </div>

                <div id={s.base_company} className={cx(s.base, baseSelected === 'vitrine' && s.selected)}>
                    <div className={s.base_header}>
                        <h3>Site web 2 à 7 pages</h3>
                        
                        <span className={s.base_price}>
                            250 €
                        </span>
                    </div>
                    <ul className={s.base_perks}>
                    <li>Installation et configuration de votre site web (https://votreadresse.com/)</li>
                        <li>Choix d'un thème graphique avec un design adapté aux mobiles, tablettes et aux ordinateurs. Nous avons des centaines de thèmes, nous sélectionnerons selon vos demandes</li>
                        <li>Customisation personalisée du thème, modification de la police, ajout de marges, choix des couleurs, retrait de blocs et ajout de blocs, pied de page et haut de page...</li>
                        <li className={s.strong}>2 à 7 pages - vous fournissez le texte et les images</li>
                        <li>Nous vous présenterons votre interface d'administration et vous accompagnerons pour son utilisation</li>
                    </ul>
                </div>

                <div id={s.base_commerce} className={cx(s.base, baseSelected === 'ecommerce' && s.selected)}>
                    <div className={s.base_header}>
                        <h3>Boutique en ligne</h3>

                        <span className={s.base_price}>
                            250 €
                        </span>
                    </div>
                    <ul className={s.base_perks}>
                        <li>Installation et configuration de votre boutique en ligne (https://votreadresse.com/)</li>
                        <li>Choix d'un thème graphique avec un design adapté aux mobiles, tablettes et aux ordinateurs. Nous avons des centaines de thèmes, nous sélectionnerons selon vos demandes</li>
                        <li>Customisation personalisée du thème, modification de la police, ajout de marges, choix des couleurs, retrait de blocs et ajout de blocs, pied de page et haut de page...</li>
                        <li>Ajout des produits - images et textes fournis par vous</li>
                        <li>Configuration des méthodes de paiement</li>
                        <li>Configuration des méthodes de livraison</li>
                        <li>Nous vous présenterons votre interface d'administration et vous accompagnerons pour son utilisation</li>
                    </ul>
                </div>
            </div>

            <h3 id={s.optionsTitle}>Et plus encore</h3>
            
            <div id={s.options}>
                {OPTIONS.map(opt => (
                    <div className={s.option}>
                        <div className={s.option_header}>
                            <p className={s.option_title}>
                                {opt.title}
                            </p>
{/* 
                            <p className={s.option_price}>
                                {!isNaN(opt.price) && (
                                    <>{opt.price} €</>
                                )}

                                {opt.price.min && opt.price.max && (
                                    <>
                                        {opt.price.min} -- {opt.price.max} €
                                    </>
                                )}
                                
                                {typeof opt.price === 'string' && (
                                    <>
                                        {opt.price}
                                    </>
                                )}
                            </p> */}
                        </div>

                        {opt.content && (
                            <p className={s.option_content} dangerouslySetInnerHTML={{__html: opt.content}}/>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}