import React from 'react';
import { Helmet } from "react-helmet"

import * as s from './tarifs-et-devis.module.scss';

import Nav from '../components/Nav';
import Hero from '../components/Hero';
import PriceCalculator from '../components/PriceCalculator';
import Footer from '../components/Footer';
import crownFavicon from '../images/crown.png';
import heroImageCut from '../images/hero-websites-design-cut.png'
import Head from '../components/Head';

export default function HomePage() {
    return (
        <div>
            <Head>
                <title>Créations et design de site webs - Websites & Design</title>
            </Head>
            <Nav />
            <Hero
                smallTitle
                color="#fcf5ea"
                rightSide={
                    <div id="quickLinks">
                        <h6>Sur cette page</h6>
                        <a href={`#${s.priceTitle}`}>Tarification</a>
                        <a href={`#${s.priceTable}`}>Tableau exemples de prix</a>
                    </div>
                }
            >
                <h1>Tarifs & Devis</h1>
            </Hero>

            <div id={s.content} className="container">
                
                <PriceCalculator />

                <h3 id={s.priceTableTitle}>
                    Exemples de prix
                </h3>
                <table id={s.priceTable}>
                    <thead>
                        <tr>
                            <th>
                                Exemples
                            </th>
                            <th>
                                Prix
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Auberge de jeunesse -- Hotel -- Lodge</strong>
                                <br />Base Site Web + réservations des chambres en ligne
                                <br/>Pages : accueil, à propos et histoire, réservation, page individuelle par chambre, page tour de l'hotel
                                <br/>Rédaction de certains contenus
                            </td>
                            <td className={s.priceCol}>400&nbsp;€</td>
                        </tr>

                        <tr>
                            <td>
                                <strong>Entreprise comptable, batiment, avocat, logistique, ...</strong>
                                <br />Base Site Web + 10 pages déclinant vos services
                                <br/>Rédaction de certains contenus
                            </td>
                            <td className={s.priceCol}>300&nbsp;€</td>
                        </tr>

                        <tr>
                            <td>
                                <strong>Boutique en ligne de vêtements, plantes, meubles</strong>
                                <br />Base E-commerce
                                <br />à essayer: nos outils marketing
                            </td>
                            <td className={s.priceCol}>250&nbsp;€</td>
                        </tr>

                        <tr>
                            <td>
                                <strong>Artiste, indépendant</strong>
                                <br />Design graphique avancé
                            </td>
                            <td className={s.priceCol}>250&nbsp;€</td>
                        </tr>
                    </thead>
                </table>

            </div>

            <Footer />
        </div>
    );
}